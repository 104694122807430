.loadingFlex {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 3px;
    width: 90%;
    margin: 30px auto;
}

.loading {
    position: relative;
    height: 350px;
    box-sizing: border-box;
    background-color: #f2f2f2;
    border-radius: 8px;
    padding: 0 8px;
}

.loadingImage {
    height: 200px;
    width: 100%;
    background-color: #dcdcdc;
    margin-bottom: 10px;
    border-radius: 4px;
    animation: spiner 2s infinite;
    margin-bottom: 25px;
}

.loadingTitle {
    height: 15px;
    width: 80%;
    background-color: #dcdcdc;
    border-radius: 4px;
    margin-bottom: 5px;
    animation: spiner 2s infinite;
}

.loadingPrice {
    height: 15px;
    width: 50%;
    background-color: #dcdcdc;
    border-radius: 4px;
    margin-bottom: 5px;
    animation: spiner 2s infinite;
}

@keyframes spiner {
    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 0.6;
    }
}


@media screen and (max-width:480px) {
    .loadingFlex {
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));

    }
}
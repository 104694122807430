.loading-ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    background: rgb(255, 255, 255);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 999;
}

.loading-letter {
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    animation: bounce 2s infinite ease-in-out;
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

.loading-letter-r {
    animation-delay: 0s;
    background-color: #214500;
    color: #ffffff;
}

.loading-letter-o {
    animation-delay: 0.15s;
    background-color: #ff0000;
    color: #000000;
}

.loading-letter-y {
    animation-delay: 0.3s;
    background-color: #00006f;
    color: #ffffff;
}

.loading-letter-e {
    animation-delay: 0.45s;
    background-color: #FFFF00;
    color: #000000;
}

.loading-letter-l {
    animation-delay: 0.6s;
    background-color: #FF00FF;
    color: #ffffff;
}

.loading-letter-s {
    animation-delay: 0.75s;
    background-color: #00FFFF;
    color: #000000;
}

.loading-letter-h {
    animation-delay: 0.9s;
    background-color: #4000ff;
    color: #ffffff;
    
}

.loading-letter-o2 {
    animation-delay: 1.05s;
    background-color: #00FF00;
    color: #000000;
}

.loading-letter-p {
    animation-delay: 1.2s;
    background-color: #ff0051;
    color: #ffffff;
}

.loading-letter-p2 {
    animation-delay: 1.35s;
    background-color: #ffff00;
    color: #000000;
}

.loading-letter-i {
    animation-delay: 1.5s;
    background-color: #9900ff;
    color: #ffffff;
}

.loading-letter-n {
    animation-delay: 1.65s;
    background-color: #1e00ff;
    color: #ffffff;
}

.loading-letter-g {
    animation-delay: 1.8s;
    background-color: #ffb700;
    color: #000000;
}
.products {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 0px;
    width: 90%;
    margin: 10px auto;

}

/* .productFirst {
    border: 1px solid #00420E;
    margin-bottom: -1px;
    margin-right: -1px;
    max-width: 300px;
} */

.product {
    margin-top: 20px;
    box-shadow: 0 0 2px 0 rgba(0, 52, 0, 0.587);
    border-radius: 8px;
    height: 345px;
    margin: 5px;
    padding: 0 5px;
    position: relative;
    transition: 0.2s;
    text-align: center;
    max-width: 300px;
}

.product:hover {
    border: 1px solid #327373;
    box-shadow: 0 0 8px 0;
    transition: 0.3s;
}

.product:hover img {
    transform: scale(1.05);
    transition: 0.3s;
}


.productImage {
    height: 200px;
    padding: 5px;
    text-align: center;
    background: white;
}

.productImage img {
    width: auto;
    height: 100%;
}

.product a {
    text-decoration: none;
    color: black;
}

.priceSection {
    display: flex;
    justify-content: center;
}

.prePrice {
    text-decoration: line-through;
    color: red;
    margin-top: 2px;
    margin-left: 7px;
}


.product p {
    padding: 0;
    margin: 0;
}

.veiwCount {
    margin: 4px 0;
}

.eyeView {
    position: absolute;
    top: 40px;
    right: 10px;
    color:#ffbc11;
    font-size: 20px;
}

.count {
    outline: none;
    border: none;
    background: none;
    text-align: center;
    margin: 0;
    padding: 0;
}

.minusCount {
    border: 0.5px solid #00420E;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    padding: 0 5px 0 10px;
    width: 25px;
    margin-right: -5px;
}

.plusCount {
    border: 0.5px solid #00420E;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    padding: 0 10px 0 5px;
    width: 25px;
    margin-left: -5px;
}

.quantityCount {
    border-top: 0.5px solid #00420E;
    border-bottom: 0.5px solid #00420E;
    width: 500px;
    padding: 0px 10px 2px;
}

.productName {
    height: 55px;
}

.orderBtn {
    margin-top: 6px;
    width: 100%;
    outline: none;
    border: none;
    background-color: orange;
    color: rgb(255, 255, 255);
    border-radius: 5px;
    border: none;
    padding: 3px;
}

.cartBtn {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    color: rgba(0, 0, 0, 0.833);
    text-shadow: 10px 10px 10px red;
    border: none;
    font-size: 16px;
    border: 1px solid rgb(255, 255, 255);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.395);
    padding: 4px;
    border-radius: 5px;
    background-color: #ffffff;
    z-index: 1;

}

.offText {
    position: absolute;
    top: 5px;
    left: 5px;
    color: rgb(255, 255, 255);
    background: orange;
    z-index: 1;
    font-size: 12px;
    border-radius: 3px;
    padding: 5px !important;
}

.blankStar {
    color: #e3e3e3;
}

.quickViewMain {
    width: 50%;
    margin: 0 auto;
}



@media screen and (max-width:575px) {
    .products {
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));

    }

    .productImage {
        height: 180px;
    }

    .product {
        height: 300px;
        max-width: 200px;
    }

    .quickViewMain {
        width: 90%;
        margin: 0 auto;
    }

    .priceSection {
        font-size: 14px;
    }

    .orderBtn {
        font-size: 14px;
    }
    .starSize{
        font-size: 12px;
    }
    .productName {
        font-size: 14px;
        height: 45px;
    }
}

@media screen and (max-width:475px) {
    .products {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

    }

    .product {
        height: 270px;
    }

    .productImage {
        height: 150px;
        padding: 10px;
    }

    

    .offText {
        font-size: 10px;
        padding: 4px !important;
    }

    .cartBtn {
        padding: 2px;
       
    }

    .quantityCount {
        width: 200px;
        padding-top: 0.5px;
    }

    .eyeView {
        top: 32px;
        right: 5px;
    }
    .productName {
        font-size: 13px;
        height: 45px;
    }

}


@media screen and (max-width:415px) {
   
    .productName {
        font-size: 12px;
    }

}
@media screen and (max-width:399px) {
    .products {
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));

    }

    .product {
        height: 255px;
    }

    .productImage {
        height: 120px;
        padding: 10px;
    }

    .productName {
        font-size: 12px;
        height: 60px;
    }

}

@media screen and (max-width:330px) {

    .productName {
        font-size: 12px;

    }

}
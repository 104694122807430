.loading {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  z-index: 9999999999999;
  }
  
  .loading .middle {
    position: absolute;
    top: 50%;
    width: 100%;
  }
  
  .loading .middle .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  
  .loading .middle .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #006aff;
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  
  .loading .middle .lds-ellipsis div:nth-child(1) {
    left: 6px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
  }
  
  .loading .middle .lds-ellipsis div:nth-child(2) {
    left: 6px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
            background: #5eff00;

  }
  
  .loading .middle .lds-ellipsis div:nth-child(3) {
    left: 26px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
            background: #ff0000;

  }
  
  .loading .middle .lds-ellipsis div:nth-child(4) {
    left: 45px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
            background: #000000;

  }
  
  @-webkit-keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @-webkit-keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
              
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  
  @keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  
  @-webkit-keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(19px, 0);
              transform: translate(19px, 0);
    }
  }
  
  @keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(19px, 0);
              transform: translate(19px, 0);
    }
  }
  
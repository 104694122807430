

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    background-color: #ffffff;
  }
  
  .loginBox {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    animation: glowing 3s infinite;
  }
  
  .loginBox h2 {
    color: #fff;
    margin-bottom: 20px;
  }
  
  .formGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .formGroup .icon {
    color: #003e80;
    margin-right: 10px;
    font-size: 18px;
  }
  
  .inputField {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 5px 1px rgba(0, 123, 255, 0.5);
    border: none;
    border-bottom: 2px solid transparent;
    outline: none;
    padding: 10px 20px;
    color: #003151;
    border-radius: 4px;
    width: 300px;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .inputField:focus {
    border-color: #007bff;
    box-shadow: 0 0 10px 1px rgba(0, 123, 255, 0.5);
  }
  


 
  .glowing {
    animation: glowings 3s infinite; 
    box-shadow: 0px 0px 50px 1px rgb(177, 202, 255);
  } 

  @media screen and (max-width : 768px) {
    .container {
      min-height: 100vh;
    }
    .inputField {
     
      width: 100%;
      
    }
    .glowing {
      animation: glowings 3s infinite; 
      
    } 
  }
.boss {
    color: red;
    animation: boss 1s infinite;
}


.mainDiv1 {
    background: rgba(0, 0, 0, 0.442);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99;
}

.mainDiv {
    display: flex;
    justify-content: center;

}

.div2 {
    position: absolute;
    top: 45%;
    height: 150px;
    width: 150px;
  
}

.div3 {
    height: 50px;
    width: 50px;
    background-color: red;
    position: absolute;
    top: 20px;
    left: 20px;
    animation: load2 2s infinite;
    border-radius: 10px;
}

.div4 {
    height: 50px;
    width: 50px;
    background-color: rgb(0, 255, 21);
    position: absolute;
    top: 20px;
    right: 20px;
    animation: load3 2s infinite ;
    border-radius: 10px;
}

.div5 {
    height: 50px;
    width: 50px;
    background-color: rgb(255, 0, 174);
    position: absolute;
    bottom: 20px;
    right: 20px;
    border-radius: 10px;
    animation: load3 2s infinite;
}

.div6 {
    height: 50px;
    width: 50px;
    background-color: rgb(4, 0, 255);
    position: absolute;
    bottom:  20px;
    left: 20px;
    border-radius: 10px;
    animation: load2 2s infinite;
}

@keyframes load2 {
    0% {
        position: absolute;
        transform: translateY(-60px);
        rotate: 90deg;
    }

    
    100% {
        position: absolute;
        transform: translateY(0px);
    }


}

@keyframes load3 {
    0% {
        position: absolute;
        transform: translateY(60px);
        rotate: 90deg;
    }

    
    100% {
        position: absolute;
        transform: translateY(0px);
    }


}



.MainImage {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
    justify-content: space-evenly;
}

.MainImage img {
    width: 45% !important;
    height: 300px !important;
    margin-top: 10px;
}


@media screen and (max-width :1300px) {
  
    .MainImage img {
        width: 45% !important;
        height: 200px !important;
        margin-top: 10px;
    }
}
@media screen and (max-width :768px) {
    .MainImage {
        width: 100%;
    }
    .MainImage img {
        width: 45% !important;
        height: auto !important;
        margin-top: 10px;
    }
}
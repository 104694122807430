.cateMain {
    width: 100%;
    background: #ffffff;
    padding: 25px;
    margin: 100px auto;
}

.cate2nd {
    display: block;
    width: 75%;
    margin: 0 auto;
    text-align: center;

}

.cateMain ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    justify-content: center;
    padding: 0;
    margin: 0;
}


.cateMain li {
    position: relative;
    /* Position for pseudo-elements */
    display: inline-block;
    /* Or any other desired display property */
    padding: 3px;
    /* Just for spacing */


}









.cateMain li p {
    margin-top: 3px;
}

.image {
    padding: 5px;
    height: 100px;
    width: 100px;
    border: 1px solid rgba(0, 0, 0, 0.268);
    margin: 5px auto 0;
    border-radius: 50%;
    overflow: hidden;
}

.image img {
    height: 90%;
    width: auto;
}

.cateMain li:hover img {
    transform: scale(1.1);
    transition: 1s;
}


@media screen and (max-width:1200px) {
    .cateMain ul {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}

@media screen and (max-width:768px) {
    .cate2nd {
        width: 95%;
        margin: 30px auto;
    }

    .cateMain ul {
        grid-template-columns: auto auto auto;
    }

    .cateMain li {
        height: auto;
        width: auto;

    }


    .image {
        height: 100px;
        width: 100px;

    }

}


@media screen and (max-width:450px) {

    .cateMain {
        padding: 0px;
        margin: 40px auto;
    }

    .image {
        height: 70px;
        width: 70px;

    }

    .cateMain li p {
        font-size: 10px;
    }

    .cateMain li {
        width: 100px;
        height: 100px;
    }

}
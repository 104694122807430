.colorSize {
    display: grid;
    grid-template-columns: 90px auto;
    margin: 5px 0;
  }
  
  
  
  
  /* styles.css */
  
  .radioInput {
    /* Hide the default radio button */
    display: none;
  }
  
  .radioLabel {
    /* Customize the radio label */
    display: inline-block;
    padding: 8px 12px;
    font-size: 14px;
    color: #333;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .radioLabel:hover {
    /* Style when hovering over the radio label */
    background-color: #e0e0e0;
  }
  
  .radioInput:checked+.radioLabel {
    /* Style for the checked radio label */
    background-color: #004812;
    color: #fff;
  }
  
  
  .radioLabelColor {
    /* Customize the radio label */
    display: inline-block;
    padding: 2px 12px;
    font-size: 14px;
    color: #333;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .radioInput:checked+.radioLabelColor {
    /* Style for the checked radio label */
    border: 1px solid rgb(12, 87, 0);
    color: #116800;
  }
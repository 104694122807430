.cartProducts {
    display: grid;
    grid-template-columns: 10% 50% 15% 15%  5%;
    grid-gap: 1%;
    font-size: 18px;
    padding: 10px 0;
}

.quantity{
    text-align: right;
    padding-right: 50%;
}

.cartProducts a {
    text-decoration: none;
}

.cartProducts a:hover {
    color: rgb(53, 110, 234);
}

.btnCuount {
    background:rgb(245, 245, 245);
    border: 0.5px solid rgba(0, 0, 0, 0.235);
    outline: none;
    border-radius: 3px;
    height: 30px;
    width: 30px;
    text-align: center;
    align-items: center;
}

@media screen and (max-width : 768px) {
    .cartProducts {
        font-size: 10px;
    }
    .btnCuount {
       padding: 0;
        height: 20px;
        width: 20px;
    }
}

@media screen and (max-width : 480px) {
    .cartProducts {
        grid-template-columns: 11% 44% 17% 15%  2%;

    }
    .cartProducts {
        font-size: 11px;
    }
    .btnCuount {
       
        height: 17px;
        width: 17px;
    }
}
.mobileCategory {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1000;
}
.mobileCategory ul {
    list-style: none;
    padding: 0 5px;
    margin: 0;
   
}

.mobileCategory li {
    padding: 10px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.246);
}

.mobileCategory  a {
  font-size: 16px;
  color:black;
  text-decoration: none;
}
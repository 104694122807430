.topNav {
    background: #ffbc11;
}

.topNav2nd {
    display: flex;
    justify-content: space-between;
    color: white;
    padding: 10px 0;
    width: 82%;
    margin: 0 auto;
}

.main {
    /* background: #012030; */
    background: #ffbc11;
    ;
    padding: 0;
    margin: 0;

}

.navx ul {
    list-style: none;
    display: flex;
    align-items: center;
    margin: auto 0;
    z-index: 999;
    padding: 20px 0;
}

.navx li {
    padding: 15px 0;
    font-size: 18px;
    color: #000000;
    position: relative;
    margin-right: 15px;
}

.navx a {
    color: #000000;
    transition: color 0.3s ease;
    text-decoration: none;
    font-family: monospace;
}

.navx ul a:hover {
    color: #204d4d;
    border-bottom: 1px solid #000000;
    padding-bottom: 5px;
}

.navx {
    display: flex;
    justify-content: space-between;
    width: 82%;
    margin: auto;
    align-items: center;
    z-index: 999;
}

.serachCateSection {
    width: 100%;
    background: #000000;
}

.serachCate2nd {
    display: grid;
    grid-template-columns: 17.5% 70%;
    width: 82%;
    margin: 0 auto;
}

.categoryBtn {
    background: #112b1a;
    ;
    cursor: pointer;
    color: #f6f6f6;
    padding: 10px;
    font-size: 19px;
}

.searchBox {
    width: 100%;
    padding: 11px 0;
    padding-left: 10px;
    background: white;
    border: 1px solid#000000;
    outline: none;
    color: #000000;

}

.search {
    display: flex;
    align-items: center;
    position: relative;
}

.searchIcon {
    font-size: 22px;
    color: #000000;
    background-color: white;
    border-bottom: 1px solid #000000;
    border-top: 1px solid #000000;
    border-right: 1px solid #000000;
    margin: auto;
    align-items: center;
    padding: 12px 20px;


}

.navbarx {
    position: fixed;
    top: 0;
    left: 0;
    background: #ffbc11;
    ;
    padding: 0px 0;
    transition: background-color 0.3s ease;
    opacity: 0;
    visibility: hidden;
    z-index: 100;
}

.scrolled {
    background: #ffbc11;
    ;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    opacity: 1;
    visibility: visible;
    transition-delay: 0.4s;
    width: 100%;

}

.arrowIcon {
    transition: 0.4s;
    transform: translateY(3px);
}

.btnLink {
    cursor: pointer;
    z-index: 5;
}

.showCates {
    transform: rotate(180deg);
    top: 22px;
    transition: 0.4s;

}


.headerCate {
    background: white;
    display: block;

}

.headerCate ul {
    list-style: none;
    position: absolute;
    top: 100%;
    left: 10vw;
    background: rgb(255, 255, 255);
    height: 400px;
    overflow-y: scroll;
    box-shadow: -3px 5px 5px rgba(0, 0, 0, 0.38);
    border-radius: 0 0px 10px 10px;
    padding: 0;
    width: 230px;
    margin-left: -15px;

}

.headerCate li {
    padding: 5px 8px;
    border-bottom: #00000013 1px solid;
}

.headerCate li:hover {
    background-color: #00000030;
}

.headerCate a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    font-size: 14px;
}


.nav2nd {
    position: relative;
}



@media screen and (max-width :1200px) {
    .navx {
        display: grid;
        grid-template-columns: 19% 44% 34%;
        justify-content: none;
        padding: 5px 20px;
    }

    .navx li {
        margin-right: 20px;
        font-size: 15px;
        padding: 0;
    }

    .categoryBtn {
        font-size: 18px;
    }

}

@media screen and (max-width : 1024px) {
    .serachCate2nd {
        width: 100%;
        grid-template-columns: 16.5% 70%;
    }

    .headerCate ul {
        margin-left: -30px;

    }

    .navx {
        width: 100%;
    }



}

@media screen and (max-width :1050px) {
    .navx {
        grid-template-columns: 19% 40% 35%;
    }

    .navx li {
        margin-right: 25px;
    }
}


@media screen and (max-width :950px) {
    .navx {
        padding: 5px 8px;
        grid-template-columns: 20% 37% 38%;
    }

    .navx li {
        margin-right: 13px;
        font-size: 14px;
    }

    .searchBox {
        width: 100%;
    }

    .categoryBtn {
        font-size: 16px;
    }
}

@media screen and (max-width :800px) {


    .categoryBtn {
        font-size: 15px;
    }
}

@media screen and (max-width :768px) {
    .topNav {
        display: none;
        ;
    }

    .main {
        display: none;
    }
}
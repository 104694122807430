
.mtop{
  margin-top: 100px;
}

.formModalMain {
  width: 50%;
  margin: 0 auto;
}

.orderImage {
  height: 100px;
  width: 100px;
  border: 0.5px solid rgba(0, 0, 0, 0.159);
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}


.radioOptionPayment {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.radioOptionPayment input[type="radio"] {
  opacity: 0;
  position: absolute;
}

.radioOptionPayment label {
  position: relative;
  padding: 10px;
  border: 2px solid #99999968;
  background-color: #f2f2f2;
  color: #333333;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  height: auto;
  width: 100%;
}

.radioOptionPayment input[type="radio"]:checked+label {
  border-color: #052c00d6;
  /* Customize the border color for the selected radio button */
  background-color: #ffffff;
  /* Customize the background color for the selected radio button */
  color: #000000;
  /* Customize the text color for the selected radio button */
}

.radioOptionPayment input[type="radio"]:disabled+label {
  border-color: #999999;
  /* Customize the border color for the disabled radio button */
  background-color: #f2f2f2;
  /* Customize the background color for the disabled radio button */
  color: #999999;
  /* Customize the text color for the disabled radio button */
}


@media screen and (max-width: 768px) {
  
.mtop{
  margin-top: 10px;
}
  .formModalMain {
    width: 95%;
    margin: 0 auto;
  }
}
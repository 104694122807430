body {
    padding: 0;
    margin: 0;
}

.mtop {
    margin-top: 100px;
}

.btn-qn:hover {
    color: black !important;
    background: white !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 21px !important;
    color: red !important;
}

.swiper-wrapper {
    margin-left: 0;
}


.mySwiper {
    margin-top: 15px;
    width: 250px;
    /* left: -100px !important;
    top: 0;
    overflow: none !important; */
    position: static !important;

}

.swippermain {
    position: relative;
    width: 320px;
    /* margin-right: auto; */
    margin: 0 auto;
}

.selectOption {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.priceRange {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
}

.priceRange2 {
    display: flex;
    justify-content: center;
}

#myinput {
    border-radius: 8px;
    height: 4px;
    width: 150px;
    outline: none;
    -webkit-appearance: none;
}

input[type='range']::-webkit-slider-thumb {
    width: 6px;
    -webkit-appearance: none;
    height: 12px;
    background: black;
    border-radius: 2px;
}


.btnCart {
    color: #000000;
}

.alert3 {
    position: fixed;
    top: 150px;
    right: 0;
    z-index: 20;
    width: 40%;
    margin: 0 30%;
}

.animate__animated.animate__fadeIn {
    --animate-duration: 5s;
    color: red;
}

.newArrival {
    width: 90%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

@media screen and (max-width: 768px) {
    .mtop {
        margin-top: 10px;
    }

    .alert3 {
        width: 70%;
        margin: 0 15%;
        top: 70px;
        font-size: 12px;
    }

    .sign-up-illustration {

        display: none;
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
        font-size: 12px !important;
        color: red !important;
    }



    .menuBar {
        display: block;
    }

    .bar1,
    .bar2,
    .bar3 {
        width: 20px;
        height: 2px;
        background-color: #000000;
        margin: 6px 0;
        transition: 0.4s;
    }


    /* This is to rotate first bar */

    .change1 {
        -webkit-transform: rotate(-45deg) translate(-6px, 3px);
        transform: rotate(-45deg) translate(-6px, 3px);
    }


    /* This is to fade out second bar */

    .change2 {
        opacity: 0;
    }


    /* This is to rotate last bar */

    .change3 {
        -webkit-transform: rotate(45deg) translate(-8px, -5px);
        transform: rotate(45deg) translate(-8px, -5px);
    }

    .priceRange {
        width: 100%;
        display: block;
    }


}

@media screen and (max-width: 350px) {

    .swippermain {
        width: 250px;
    }

    .mySwiper {
        width: 200px;

    }

}